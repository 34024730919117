var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row content-container",staticStyle:{"margin-top":"13vh"}},[_c('div',{staticClass:"consigne",staticStyle:{"height":"70px","min-width":"295px","margin-left":"-110px","margin-right":"-80px"}},[_c('div',{staticClass:"text-center",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_1,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
          fontWeight: 'regular',
          'text-transform': 'uppercase',
        })},[_c('p',[_vm._v("Votre signature")])]),_c('div',{staticClass:"text-center",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_2,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          'font-weight': 'regular',
        })},[_c('p',[_vm._v("Signez avec votre doigt à l'intérieur du cadre")])])]),_c('div',{staticClass:"d-flex justify-center mb-4 mt-5"},[_c('drawer',{ref:"drawer",style:({
          height: '70vh',
        }),attrs:{"clear":_vm.clearCanvas},on:{"update:clear":function($event){_vm.clearCanvas=$event},"empty":function($event){_vm.canvasEmpty = $event},"end":_vm.send}})],1),_c('div',{staticClass:"d-flex flex-row align-center consigne",staticStyle:{"margin-left":"-230px"}},[_c('v-btn',{staticClass:"mt-4",style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          marginTop: '10px',
          padding: '25px 100px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '20px',
          width: '260px',
          height: '52px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.canvasEmpty || _vm.uploading,"loading":_vm.uploading},on:{"click":_vm.validate}},[_vm._v("Valider")]),_c('v-btn',{style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_2,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_2,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          marginBottom: '-14px',
          padding: '25px 100px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '10px',
          width: '260px',
          height: '52px',
          marginLeft: '20px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_2,"disabled":_vm.canvasEmpty || _vm.uploading},on:{"click":function($event){return _vm.$refs.drawer.clearCanvas()}}},[_vm._v(" Recommencer ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }